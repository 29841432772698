@mixin card-autocomplete {
  .card-body {
    padding: 0;

    .form-group {
      margin-bottom: 0;

      .rbt-menu {
        li {
          border-top: 1px solid #c2cfd6;

          &:first-child {
            border-top: 0;
          }

          .dropdown-item {
            white-space: normal;
          }
        }
      }
    }

    .text-danger {
      padding: 10px 12px;
    }
  }
}

.card-autocomplete {
  @include card-autocomplete;
}
