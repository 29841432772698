.selected-list {
  margin: 0;
  padding: 0;
  list-style: none;

  .selected-list__item {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    border-top: 1px solid #c2cfd6;
    background-color: #e7f4ff;
    color: #1f8dd6;

    &:first-child {
      border-top: 0;
    }

    .selected-list__item__drag,
    .selected-list__item__delete {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      min-width: 30px;
      height: 30px;
    }

    .selected-list__item__title,
    .selected-list__item__type {
      padding: 10px;
    }

    &.selected-list__item__drag-area {
      padding: 15px 20px;
      background-color: #fff;
      color: #151b1e;
    }
  }

  .selected-list__item_native {
    background-color: rgb(94 98 134 / 80%);
    color: white;

    .selected-list__item__delete {
      color: white;

      &:hover {
        color: white;
      }
    }
  }
}
