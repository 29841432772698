// TODO - fix excessive nesting, probably refactor to css modules
/* stylelint-disable max-nesting-depth */
.expandable-content {
  .expandable-content__main-heading {
    margin-bottom: 1rem !important;
  }

  .expandable-content__list {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      margin: 0;
      padding: 0;

      .expandable-content__item {
        margin-bottom: 15px;
        padding: 15px;
        border: 1px solid #dbdbdb;
        background-color: #f8f9f9;

        .expandable-content__item__content__headline {
          margin-bottom: 1rem;
        }

        .expandable-content__item__toolbar {
          display: flex;

          button {
            margin-left: 4px;
          }

          button:first-child {
            margin-left: auto;
          }
        }
      }
    }
  }
}
