.daypickerWrapper {
  z-index: 99999;

  :global {
    .rdp-dropdown_month {
      text-transform: capitalize;

      > select {
        text-transform: capitalize;
      }
    }

    .rdp-button {
      outline: none;
    }

    .rdp-today {
      font-weight: bold;
    }
  }
}

.daypicker {
  :global {
    @media (width <= 768px) {
      .rdp-months {
        flex-direction: column;
      }
    }
  }
}

.daypickerWrapperRange {
  :global {
    .rdp-caption_start {
      .daypicker-footer {
        display: none !important;
      }
    }
  }
}

.daypickerSingleSelection {
  :global {
    .rdp-day_selected {
      background-color: #367ebd !important;
    }
  }
}

.daypickerRange {
  :global {
    .rdp-row:not(:last-child) {
      border-bottom: 2px solid #fff;
    }

    .rdp-cell:has(.rdp-day_range_start) {
      border-top-left-radius: 50%;
      border-bottom-left-radius: 50%;
      background-color: #ebf4f8;
    }

    .rdp-cell:has(.rdp-day_range_end) {
      border-top-right-radius: 50%;
      border-bottom-right-radius: 50%;
      background-color: #ebf4f8;
    }

    .rdp-day_range_middle,
    .rdp-day_selected,
    .rdp-day_selected:hover {
      transition: 0.3s;
      background-color: #ebf4f8;
      color: #000;
    }

    .rdp-day_range_start,
    .rdp-day_range_end {
      border-radius: 50% !important;
      background-color: #367ebd !important;
      color: #fff !important;
    }

    .rdp-day_selected:hover {
      border: 2px solid #367ebd;
    }
  }
}
