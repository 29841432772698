.img-avatar {
  border-radius: 50em;
}

.avatar {
  $width: 36px;
  $status-width: 10px;
  @include avatar($width, $status-width);
}

.avatar.avatar-xs {
  $width: 20px;
  $status-width: 8px;
  @include avatar($width, $status-width);
}

.avatar.avatar-sm {
  $width: 24px;
  $status-width: 8px;
  @include avatar($width, $status-width);
}

.avatar.avatar-lg {
  $width: 72px;
  $status-width: 12px;
  @include avatar($width, $status-width);
}

.avatars-stack {
  .avatar.avatar-xs {
    margin-right: -10px;
  }

  .avatar {
    margin-right: -15px;
    transition:
      margin-left $layout-transition-speed,
      margin-right $layout-transition-speed;

    &:hover {
      margin-right: 0 !important;
    }
  }
}
