// Override Boostrap variables
@import 'bootstrap-variables';

// Import third party styles
@import '~font-awesome/css/font-awesome.min.css';
@import '~simple-line-icons/css/simple-line-icons.css';
@import '~react-bootstrap-typeahead/css/Typeahead.css';
@import '~react-bootstrap-typeahead/css/Typeahead-bs4.css';

// Import Bootstrap source files
@import '~bootstrap/scss/bootstrap';

// Import core styles
@import 'core/core';

// Custom styles
@import 'mixins';
@import 'custom';
@import 'archive-images';
@import 'expandable-content';
@import 'offers';
@import 'selected-list';
@import 'paragraphs';

// Reset
button,
input,
select,
textarea {
  border-style: none;
}

// https://github.com/csstools/sanitize.css/issues/203
nav li::before {
  position: absolute;
}

// Temp fix for reactstrap
.app-header {
  .navbar-nav {
    .dropdown-menu-right {
      right: auto;
    }
  }
}
