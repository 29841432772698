.progress-xs {
  height: 4px;
}

.progress-sm {
  height: 8px;
}

// White progress bar
.progress-white {
  background-color: rgb(255 255 255 / 20%) !important;

  .progress-bar {
    background-color: #fff;
  }
}
