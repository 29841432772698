// Angular Version
// Make clicks pass-through
#loading-bar,
#loading-bar-spinner {
  transition: 350ms linear all;
  -webkit-pointer-events: none;
  pointer-events: none;
}

#loading-bar.ng-enter,
#loading-bar.ng-leave.ng-leave-active,
#loading-bar-spinner.ng-enter,
#loading-bar-spinner.ng-leave.ng-leave-active {
  opacity: 0;
}

#loading-bar.ng-enter.ng-enter-active,
#loading-bar.ng-leave,
#loading-bar-spinner.ng-enter.ng-enter-active,
#loading-bar-spinner.ng-leave {
  opacity: 1;
}

#loading-bar .bar {
  position: fixed;
  z-index: 20002;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  transition: width 350ms;
  border-top-right-radius: 1px;
  border-bottom-right-radius: 1px;
  background: theme-color('primary');
}

// Fancy blur effect
#loading-bar .peg {
  position: absolute;
  top: 0;
  right: 0;
  width: 70px;
  height: 2px;
  border-radius: 100%;
  opacity: 0.45;
  box-shadow: #29d 1px 0 6px 1px;
}

#loading-bar-spinner {
  display: block;
  position: fixed;
  z-index: 10002;
  top: 10px;
  left: 10px;
}

#loading-bar-spinner .spinner-icon {
  width: 14px;
  height: 14px;
  animation: loading-bar-spinner 400ms linear infinite;
  border: solid 2px transparent;
  border-radius: 50%;
  border-top-color: #29d;
  border-left-color: #29d;
}

@keyframes loading-bar-spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

// Ajax & Static Version
.pace {
  -webkit-pointer-events: none;
  pointer-events: none;
  user-select: none;
}

.pace-inactive {
  display: none;
}

.pace .pace-progress {
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 100%;
  width: 100%;
  height: 2px;
  background: theme-color('primary');
}
