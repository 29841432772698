// TODO - fix excessive nesting, probably refactor to css modules
/* stylelint-disable max-nesting-depth */
.archive-images {
  margin-bottom: 0;

  .archive-images__item {
    margin-top: 1rem;
    padding: 15px;
    border: 1px solid #dbdbdb;
    background-color: hsl(180deg 8% 97%);

    .archive-images__item__menu {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;

      button {
        margin: 0 0 0 4px;
      }
    }

    .archive-images__item__focus {
      position: relative;
      top: 0;
      left: 0;
      padding: 0;

      &:focus {
        outline: none;
      }

      .archive-images__item__focus__point {
        position: absolute;
        top: 0;
        left: 0;
        width: 26px;
        height: 26px;
        margin-top: -13px;
        margin-left: -13px;
        color: #f00;
        text-shadow: 0 0 2px #000;
        user-select: none;
      }

      .archive-images__item__focus__image {
        display: block;
        width: 100%;
      }
    }

    .archive-images__item__divider {
      margin-top: 30px;
      margin-bottom: 30px;
    }

    .archive-images__item__actions {
      display: flex;
      margin-top: 10px;

      .btn {
        flex: 1;
      }
    }

    .archive-images__item__focus-instructions {
      display: flex;
      flex-direction: column;
      gap: 3px;
      margin-top: 15px;
      margin-bottom: 15px;
      padding: 20px;
      border: 1px solid #dbdbdb;
      border-radius: 3px;
      background-color: #fff;
      text-align: center;

      @media (width >= 564px) {
        margin-top: 0;
      }
    }

    .archive-images__item__photographer {
      margin-top: 10px;

      &.form-group,
      .form-group {
        margin-bottom: 0;
      }

      .archive-images__item__photographer__label {
        font-weight: bold;
      }
    }

    .archive-images__item__caption {
      margin-top: 15px;
      margin-bottom: 0;

      .form-group {
        margin-bottom: 0;
      }
    }
  }

  .archive-images__add {
    display: block;
    margin-right: auto;
    margin-left: auto;
    text-align: center;

    &.archive-images__add-images-exists {
      margin-top: 1rem;
    }

    button {
      min-width: 150px;
    }
  }
}

.archive-images-modal {
  .archive-images-modal__list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 0 -2%;
    padding: 0;
    list-style-type: none;

    .archive-images-modal__list__item {
      position: relative;
      box-sizing: border-box;
      flex: 1 0 46%;
      max-width: 46%;
      height: 120px;
      margin: 0 2% 10px;
      border: 1px solid #e4e4e4;

      @media (width >= 564px) {
        flex-basis: 21%;
        max-width: 21%;
      }

      &.archive-images-modal__list__item-pay {
        border: 2px solid #e14949;
      }

      &.archive-images-modal__list__item-selectable {
        .archive-images-modal__list__item__image {
          cursor: pointer;
        }
      }

      .archive-images-modal__list__item__image {
        position: absolute;
        width: 100%;
        height: 100%;
        padding: 0;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }

      .archive-images-modal__list__item__pay-web,
      .archive-images-modal__list__item__pay-paper {
        display: flex;
        position: absolute;
        top: 0;
        right: 0;
        align-items: center;
        justify-content: center;
        width: 25px;
        height: 25px;
        background-color: rgb(255 255 255 / 60%);
      }

      .archive-images-modal__list__item__pay-paper {
        top: 25px;
      }

      .archive-images-modal__list__item__select {
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
      }
    }
  }

  .archive-images-modal__detail {
    .archive-images-modal__detail__content {
      display: grid;
      gap: 1rem;

      @include media-breakpoint-up(lg) {
        grid-template-columns: 2fr 1fr;
      }

      .archive-images-modal__detail__content__image {
        display: inline-block;
        padding: 0;
        cursor: pointer;

        img {
          width: 100%;
        }
      }

      .archive-images__detail__content__data {
        padding: 20px;
        border: 1px solid #e4e4e4;
        background-color: #f3f3f3;

        .archive-images__detail__content__data__item {
          display: flex;

          &:first-child {
            margin-top: 0;
          }

          .archive-images__detail__content__data__item__label {
            min-width: 70px;
            margin-right: 5px;
            font-weight: bold;
            white-space: nowrap;
          }
        }
      }

      .archive-images__detail__content__error {
        margin-top: 1rem;
      }
    }

    .archive-images-modal__detail__menu {
      display: flex;
      margin-top: 1rem;
      margin-bottom: 0;

      button:first-child {
        margin-right: 1rem;
      }
    }
  }
}

.archive-images-preview-modal-wrapper {
  .archive-images-preview-info {
    width: 100%;
    margin-bottom: 10px;
    padding: 10px;
    background-color: #20a8d8;
    color: #fff;

    i {
      margin-right: 5px;
    }
  }

  .archive-images-preview__image__grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 10px;
    margin-bottom: 10px;

    .archive-images-preview__image-wrapper {
      position: relative;
      background-color: #e4e4e4;

      .archive-images-preview__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .archive-images-preview__image__ratio {
        position: absolute;
        top: 0;
        right: 0;
        padding: 5px;
        background-color: #20a8d8;
        color: white;
        font-size: 12px;
      }

      .archive-images-preview__image__focus {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 5px;
        background-color: #dc3545;
        color: white;
        font-size: 12px;
      }
    }
  }
}
