.app-footer {
  @include borders($footer-borders);

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 $spacer;
  background: $footer-bg;
  color: $footer-color;
}
