.base {
  width: 40px;
  height: 40px;
}

.overlay {
  position: absolute;
  z-index: 1;
  top: 80px;
  left: 50%;
  transform: translate(-50%);
}

.static {
  position: relative;
  margin: 2em auto;
}

.icon {
  color: #999;
}
