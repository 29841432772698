.paragraphs {
  margin-top: 20px;

  .card {
    margin-bottom: 0;

    .card-header {
      display: flex;
      align-items: center;

      :nth-child(2) {
        margin-left: auto;
      }

      button {
        margin: 0 0 0 4px;
      }
    }

    .card-body {
      .form-group:last-child {
        margin-bottom: 0;
      }
    }
  }

  .paragraph-related_content {
    @include card-autocomplete;
  }

  .paragraphs__menu {
    .paragraphs__menu__toggle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 40px;
      margin: 0;
      padding: 0;
      outline: 0;

      .paragraphs__menu__toggle__line {
        width: 100%;
        height: 1px;
        border-top: 1px solid #ccc;
      }

      .paragraphs__menu__toggle__icon {
        margin-right: 10px;
        margin-left: 10px;
        transform: rotate(0deg);
        transition: all 0.1s ease-in-out;
      }

      &.paragraphs__menu__toggle-open {
        .paragraphs__menu__toggle__icon {
          transform: rotate(45deg);
        }
      }
    }

    .paragraphs__menu__items {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: 0;
      padding: 0;
      list-style-type: none;

      li {
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }
  }
}
