.table-outline {
  border: 1px solid $table-border-color;

  td {
    vertical-align: middle;
  }

  td.buttons {
    padding: 0 5px;
    text-align: right;
    vertical-align: middle;
  }
}

.table-align-middle {
  td {
    vertical-align: middle;
  }
}

.table-clear {
  td {
    border: 0;
  }
}

.ck-editor-intable {
  padding: 0;

  .ck-editor__editable {
    height: 100%;
  }

  .form-group {
    margin-bottom: 0;
  }
}

.ck-editor-in-dd {
  padding-top: 0.5rem;

  .ck-editor__editable {
    height: 100%;
  }
}
