.daypickerFooter {
  display: flex;
  flex-direction: column;
}

.timePicker {
  width: 100px;
  margin: 0 0.5rem 0.5rem;
  border-radius: 0 !important;
}

.pickerBtn {
  width: 60px;
  font-weight: bold;
}
