.offers__list {
  margin: 0;
  padding: 0;
  list-style-type: none;

  > li {
    margin: 0;
    padding: 0;
  }

  .offers__item {
    margin-bottom: 15px;
    padding: 15px;
    border: 1px solid #dbdbdb;
    background-color: #f8f9f9;

    .offers__item__product {
      margin-bottom: 1rem;
    }

    .offers__item__toolbar {
      display: flex;

      button {
        margin-left: 4px;
      }

      button:first-child {
        margin-left: auto;
      }
    }
  }
}
