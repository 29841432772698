// Here you can add other styles
.app-header.navbar .navbar-brand {
  background-size: 75% auto;
}

.profile-item {
  position: relative;
  min-height: 36px;

  .img-avatar {
    position: absolute;
    top: 50%;
    width: 36px;
    height: 36px;
    margin-top: -18px;
    overflow: hidden;
    border-radius: 50%;
    background-color: rgb(0 0 0 / 5%);
    background-position: center;
    background-size: cover;
  }

  .profile-item-text {
    padding-left: 45px;
  }

  &.profile-item-small {
    min-height: 26px;
    line-height: 26px;

    .img-avatar {
      width: 26px;
      height: 26px;
      margin-top: -13px;
    }

    .profile-item-text {
      padding-left: 40px;
    }
  }

  &.profile-item-inline {
    padding-top: 10px;
  }
}

.form-control::placeholder {
  color: #b1b5b9;
}

.av-validate {
  .text-danger {
    .form-control {
      &.rbt-input {
        border-color: #{$red};
      }
    }

    .av-cke-container {
      border: 1px solid #{$red};
    }
  }
}

.cell-hover td {
  cursor: pointer;

  &:hover {
    background-color: rgb(0 0 0 / 7.5%);
  }
}

.modal-xl {
  @include media-breakpoint-up(xl) {
    max-width: 1024px;
  }
}

.rbt-input {
  min-width: 150px;
}

.rbt-menu-custom-option {
  span::before {
    content: ' ';
  }
}

.form-control.rbt-input-multi.rbt-input {
  height: auto;
  min-height: calc(2.0625rem + 2px);
}

// Fix broken select when invalid
select.form-control:invalid {
  background-size: auto;
}

.table.dataTable td {
  vertical-align: top;
}

table.dataTable td.buttons {
  padding: 0 5px;
  text-align: right;
  vertical-align: middle;
}

table.dataTable thead th {
  vertical-align: top;
}

table.dataTable thead .sorting_hide_icon.sorting::before,
table.dataTable thead .sorting_hide_icon.sorting::after,
table.dataTable thead .sorting_hide_icon.sorting_asc::before,
table.dataTable thead .sorting_hide_icon.sorting_asc::after,
table.dataTable thead .sorting_hide_icon.sorting_desc::before,
table.dataTable thead .sorting_hide_icon.sorting_desc::after {
  display: none;
}

table.dataTable thead .sorting_hide_icon.sorting_asc,
table.dataTable thead .sorting_hide_icon.sorting_desc,
table.dataTable thead .sorting_hide_icon.sorting {
  padding: 0.75rem;
}

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc {
  padding-right: 0;
  padding-left: 22px;
}

table.dataTable.table-sm thead .sorting,
table.dataTable.table-sm thead .sorting_asc,
table.dataTable.table-sm thead .sorting_desc {
  padding-left: 19px;
}

table.dataTable thead .sorting::before,
table.dataTable thead .sorting_asc::before,
table.dataTable thead .sorting_desc::before,
table.dataTable thead .sorting_asc_disabled::before,
table.dataTable thead .sorting_desc_disabled::before {
  position: absolute;
  top: 0;
  right: auto;
  left: 5px;
}

table.dataTable thead th.sorting::before,
table.dataTable thead th.sorting_asc::before,
table.dataTable thead th.sorting_desc::before,
table.dataTable thead th.sorting_asc_disabled::before,
table.dataTable thead th.sorting_desc_disabled::before {
  top: auto;
}

table.dataTable thead .sorting::after,
table.dataTable thead .sorting_asc::after,
table.dataTable thead .sorting_desc::after,
table.dataTable thead .sorting_asc_disabled::after,
table.dataTable thead .sorting_desc_disabled::after {
  position: absolute;
  top: 0;
  right: auto;
  left: 0.5em;
}

table.dataTable thead th.sorting::after,
table.dataTable thead th.sorting_asc::after,
table.dataTable thead th.sorting_desc::after,
table.dataTable thead th.sorting_asc_disabled::after,
table.dataTable thead th.sorting_desc_disabled::after {
  top: auto;
}

// Hide arrow icons displaying wrong order
table.dataTable thead th.sorting::after,
table.dataTable thead th.sorting_asc::after,
table.dataTable thead th.sorting_desc::before {
  display: none;
}

option.scratched,
option[disabled] {
  background-color: #eee !important;
  color: #999;
}

.scratched {
  color: #999;
  text-decoration: line-through;
}

.ck-editor__editable {
  height: 350px;

  td,
  th {
    height: auto;

    p {
      margin: 0;
    }
  }
}

.equipments-form {
  .form-group {
    margin-bottom: 0;
  }

  td {
    height: 50px;
    padding-top: 6px;
    padding-bottom: 6px;
    vertical-align: middle;
  }
}

.custom-checkbox.custom-checkbox-disabled {
  color: $gray-500;
}

.driver-tag-icon {
  height: 1rem;
  background-image: $driver-tag-icon;
}

.horse-tag-icon {
  height: 1.5rem;
  background-image: $horse-tag-icon;
}

.trainer-tag-icon {
  height: 1rem;
  background-image: $trainer-tag-icon;
}

.track-tag-icon {
  height: 1rem;
  background-image: $track-tag-icon;
}

.tag-dropdown {
  display: flex;
  align-items: center;
}

.content-sidebar {
  display: flex;
  flex-wrap: wrap;

  > div {
    width: 100%;
  }

  .content-preview {
    margin-bottom: 1rem;
  }

  .card-body > div:last-child {
    margin-bottom: 0;
  }

  .content-actions {
    display: flex;

    @media (width >= 768px) {
      order: -1;
      margin-bottom: 1rem;
    }

    .btn {
      flex: 1;
    }
  }

  @media (width >= 768px) {
    .topic,
    .content-preview {
      order: 1;
    }
  }
}

.field-misprint {
  label {
    margin-bottom: 0;
    cursor: pointer;

    i {
      font-size: 0.7rem;
    }
  }

  .form-group {
    margin-top: 0.5rem;
    margin-bottom: 0;

    textarea {
      height: 100px;
    }
  }
}

.content-list {
  .content-list__header {
    > div {
      margin-right: 0.7rem !important;
      margin-bottom: 0.7rem !important;
    }

    .form-group {
      margin-bottom: 0;
    }

    .content-list__header__menu {
      width: 150px;
    }

    .content-list__header__headline {
      flex: 1;
      min-width: 200px;

      input {
        width: 100%;
      }
    }

    .content-list__header__authors {
      width: 200px;

      .rbt {
        width: 200px;

        .form-control {
          width: 100%;
        }
      }
    }

    .content-list__header__story {
      width: 200px;

      .rbt {
        width: 200px;

        .form-control {
          width: 100%;
        }
      }

      .selected-list__item__title {
        padding-top: 7px;
        padding-bottom: 7px;
      }
    }
  }

  .content-list__body {
    font-size: 0.8rem;

    th,
    td.content-list__updated,
    td.content-list__publish_start,
    td.content-list__publish_end {
      white-space: nowrap;
    }

    th.content-list__status {
      text-align: center;
    }

    td {
      vertical-align: middle;
    }

    th:first-child,
    td:first-child {
      padding-left: 10px;
    }
  }
}

.video-field {
  .video-field__content {
    display: block;
    margin-top: 1rem;

    @media (width >= 768px) {
      display: flex;
    }

    .video-field__content__fields {
      margin-top: 1rem;

      .form-group:nth-child(2) {
        margin-top: 1rem;
      }

      @media (width >= 768px) {
        width: 100%;
        margin-top: 0;
        margin-left: 1rem;
      }
    }
  }
}

.editor__highlight {
  background-color: #ff0;
}

.editor__fineprint {
  color: #808080;
  font-size: 80%;
}

.headline-card {
  .card-header {
    .card-actions {
      margin-right: 1rem;
      padding-top: 0.75rem;
    }
  }
}

.popover-scrollable {
  max-height: 50vh;
  overflow-y: auto;
}
