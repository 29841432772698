.daypickerToggler {
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 200px;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;

  &:focus {
    border-color: #8ad4ee !important;
    outline: 0;
    background-color: #fff;
    box-shadow: 0 0 0 0.2rem rgb(32 168 216 / 25%);
    color: #3e515b;
  }
}
